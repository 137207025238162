<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><router-link :to="{name:'home'}">Home</router-link></li>
          <li><a href="javascript:void(0);">Terms & Condition</a></li>
        </ul>
      </div>
    </div>
    <section class="section-terms ps-page--business">
      <div class="container">
        <h2 class="page__title">Terms & Condition</h2>
        <div class="terms-content">
          <h4>AGREEMENT TO TERMS</h4>
        <p>
         The Site is provided to you free of charge for your personal use subject to these Terms. By using the Site you agree to be bound by these Terms. All orders and purchases made on the Site will also be governed by the relevant Product Terms below:
        </p>
        <ul>
          <li>Grocere-Online</li>
          <li>Delivere-Online</li>
          <li>Gift Delivery -online</li>
        </ul>

          <div class="heading">
              Amendments
          </div>
          <p>
            We may update these Terms from time to time and any changes will be notified to you via the e-mail address provided by you on registration or via a suitable announcement on the Site. The changes will apply to the use of the Site after we have given notice. If you do not wish to accept the new Terms you should not continue to use the Site and the Online Stores. If you continue to use the Site after the date on which the change comes into effect, your use of the Site indicates your agreement to be bound by the new Terms.
          </p>

        <div class="heading">
          Registration
        </div>
          <p>
            To register on the Site you must be over eighteen years of age. You must ensure that the details provided by you on registration or at any time are correct and complete. Please inform us of any changes to your information by updating your personal details to ensure that our records are correct.
          </p>

          <div class="heading">
          Password and security
          </div>
          <p>
            When you register to use the Site you will be asked to create a password. You must keep this password confidential and must not disclose it or share it with anyone. You will be responsible for all activities and orders that occur or are submitted under your password. If you know or suspect that someone else knows your password you should notify us by contacting Customer Services (see below for contact details). If suyeemarket.com has reason to believe that there is likely to be a breach of security or misuse of the Site, we may require you to change your password or we may suspend your account in accordance as stated below.
          </p>

          <div class="heading">
          Intellectual property
          </div>
          <p>
          •	The content of the Site is protected by copyright, trade marks, database and other intellectual property rights. You may retrieve and display the content of the Site on a computer screen, store such content in electronic form on disk (but not any server or other storage device connected to a network) or print one copy of such content for your own personal, non-commercial use, provided you keep intact all and any copyright and proprietary notices. You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content on the Site without written permission from suyeemarket.com. No licence is granted to you in these Terms to use any trade mark suyee-family.loveoolove.com or its affiliated companies including, without limitation, the trade marks Grocere, Delivery, Grocere-online,Delivery-online and Gift Delivery –online.
          </p>


          <div class="heading">
          Availability of the S
          </div>
          <p>
            Although suyeemarket.com aims to offer you the best service possible, suyeemarket.com makes no promise that the services at the Site will meet your requirements. suyeemarket.com cannot guarantee that the service will be fault free. If a fault occurs in the service you should report it to the Customer Services (see below for contact details) or by email at suyeemarket@gmail.com and we will attempt to correct the fault as soon as we reasonably can. Your access to the Site may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. suyeemarket.com will attempt to restore the service as soon as it reasonably can.
          </p>

          <div class="heading">
          Suyeemarket.com right
          </div>
          <p>
            to suspend or cancel your registration
          suyeemarket.com may suspend or cancel your registration immediately at our reasonable discretion or if you breach any of your obligations under these Terms. The suspension or cancellation of your registration and your right to use the Site shall not affect either party's rights or liabilities.
            </p> 
            

          <div class="heading">
          Delivery Area
          </div>
          <p>

          
          The minimum delivery charge is different from place to place in the current situations.
          suyeemarket.com's liability
          The content on the Site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on the Site. Although we make reasonable efforts to update the information on the Site, we make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up to date. Nothing in this paragraph applies to suyeemarket.com's liability in respect of products sold through the Online Stores.
</p>
          <div class="heading">
          Third Party Websites
          </div>
          <p>
          As a convenience to suyeemarket.com customers, the Site includes links to other websites or material which are beyond its control. Suyeemarket.com is not responsible in any way
          for content outside the Site.
</p>

          <div class="heading">
          Applicable Law
          </div>
          <p>
          These Terms shall be governed by and construed in accordance with the laws of Worldwide and any disputes will be decided only by the English courts.
</p>
          <div class="heading">
          International Use
          </div>
          <p>
          Suyeemarket.com makes no promise that materials on the Site are appropriate or available for use in locations outside the Worldwide.
</p>
          <div class="heading">
          Miscellaneous
          </div>
          <p>
          You may not assign, sub-license or otherwise transfer any of your rights under these Terms. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of that provision will not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. If you breach these Terms and suyeemarket.com ignores this, suyeemarket.com will still be entitled to use its rights and remedies at a later date or in any other situation where you breach the Terms. Nothing in these Terms shall exclude Suyeemarket.com's liability for fraudulent misrepresentation or personal injury or death caused by its negligence.
</p>
          <p>
          The site is owned and operated by Suyee & family Marketing Company pvt, No.15/1, Malwatta Cross Lane, Dehiwala,post code:10350.If you have any queries please contact Customer Services at Suyeemarket.com or call +1 973 341 0582 or +94 76 851 4527. Company registration number 00222101.
          </p>
          <div class="heading">
            Payment
          </div>
  <p>
          Able to payment through credit card usage and charges.

  </p>

        </div>
      </div>
    </section>
  </main>
</template>
<style>
  .terms-content{
    margin: 20px 0;
  }
  .terms-content .heading {
    color: #000000;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 3px;
}
.terms-content ul {
    list-style: disc;
    padding-left: 20px;
}
</style>